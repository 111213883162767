import { Controller } from '@hotwired/stimulus'
import Cookies from 'js-cookie'
import ApexCharts from 'apexcharts'

export default class extends Controller {
  connect() {
    this.unit = Cookies.get('preferred_unit') || 'kg'
    this.currentTimeFrame = '7_days'
    this.unloadChart()
    this.loadChart()
  }

  disconnect() {
    this.unloadChart
  }

  updateChart(event) {
    this.currentTimeFrame = event.target.value
    this.loadChart()
  }

  toggleUnit(event) {
    this.unit = event.target.value
    Cookies.set('preferred_unit', this.unit)
    this.loadChart()
  }

  loadChart() {
    fetch(`/weights/dashboard.json?time_frame=${this.currentTimeFrame}`)
      .then((response) => response.json())
      .then((data) => this.prepareChartData(data))
      .catch((error) => console.error('Error loading chart data:', error))
  }

  prepareChartData(data) {
    const convertedData = this.convertWeights(data)
    const formattedCategories = this.formatCategories(convertedData)
    const seriesData = formattedCategories.map((date) => convertedData[date])
    this.renderChart(formattedCategories, seriesData)
  }

  formatCategories(data) {
    const keys = Object.keys(data)
    return keys.filter((key) => this.filterDates(key, this.currentTimeFrame))
  }

  filterDates(date, timeFrame) {
    const dateObj = new Date(date)
    switch (timeFrame) {
      case 'last_year':
      case 'all':
        return dateObj.getDate() === 1
      case '90_days':
        return dateObj.getDate() === 1
      default:
        return true
    }
  }

  renderChart(categories, seriesData) {
    const chartOptions = this.getChartOptions(categories, seriesData)
    if (this.chart) {
      this.chart.updateOptions(chartOptions, true)
    } else {
      this.chart = new ApexCharts(
        this.element.querySelector('#chart'),
        chartOptions
      )
      this.chart.render()
    }
  }

  getChartOptions(categories, seriesData) {
    return {
      theme: { mode: 'dark' },
      series: [{ name: 'Weight', data: seriesData }],
      chart: {
        type: 'line',
        height: 350,
        toolbar: { show: false },
        animations: { enabled: false }
      },
      stroke: { curve: 'smooth' },
      xaxis: {
        categories: categories.map((date) => this.formatDateLabel(date))
      },
      yaxis: {
        labels: {
          formatter: (value) => value.toFixed(2)
        }
      }
    }
  }

  formatDateLabel(dateString) {
    // Assuming dateString is in ISO format, you can include the time zone offset manually if not present.
    const date = new Date(dateString + 'T00:00:00Z') // Append 'Z' to interpret as UTC if server sends UTC dates
    const options =
      this.currentTimeFrame === 'all_time'
        ? { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' }
        : { month: 'short', day: 'numeric', timeZone: 'UTC' }
    return date.toLocaleDateString('en-US', options)
  }

  convertWeights(data) {
    return Object.entries(data).reduce((result, [date, value]) => {
      const weight = parseFloat(value)
      result[date] = this.unit === 'lbs' ? this.kgToLbs(weight) : weight
      return result
    }, {})
  }

  kgToLbs(kg) {
    return kg * 2.20462
  }
  LbsToKg(lbs) {
    return lbs / 2.20462
  }

  unloadChart() {
    if (this.chart) {
      this.chart.destroy()
      this.chart = null
    }
  }
}
